import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' 
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'   
import Avatar from 'vue-avatar'
import VueAutosuggest from "vue-autosuggest";


import 'vue-multiselect-inv/dist/vue-multiselect.min.css'

import VCalendar from 'v-calendar';

Vue.component('Avatar', Avatar) 
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar /> 
});
Vue.use(VueAutosuggest);

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

 