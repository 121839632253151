<template>
 <div class="login_wrap">
    <div class="login_cnt">
        <figure class="login_img"><img src="@/assets/images/login_img.svg"></figure>
        <figure><img src="@/assets/images/kanha_logo.svg"></figure> 
        <router-link to="/dashboard">
          <button class="login_btn"><img src="@/assets/images/g-img.svg">Sign In with Gmail</button>
        </router-link> 
    </div>
  </div> 
</template>

<script>
//import footerView from "@/components/footer.vue"
export default {
  name: 'KanhaOne',
  components: {
     
    //footerView
  },
  props: {
    msg: String
  }
}
</script>

