import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  //after login
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/dashboard', 
        name: 'dashboard', 
        meta: {
          requiresAuth: true,
          title:'Dashboard',
          hidetopnav:true
        },
        component: () => import('@/views/dashboard/dashboard.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/clients', 
        name: 'clients', 
        meta: {
          requiresAuth: true,
          title:'Dashboard',
          hidetopnav:true
        },
        component: () => import('@/views/clients/clients.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/task', 
        name: 'task', 
        meta: {
          requiresAuth: true,
          title:'Task',
          hidetopnav:true
        },
        component: () => import('@/views/task/task.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/documents', 
        name: 'documents', 
        meta: {
          requiresAuth: true,
          title:'Client List',
          hidetopnav:true
        },
        component: () => import('@/views/documents/documents.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/members', 
        name: 'members', 
        meta: {
          requiresAuth: true,
          title:'Members',
          hidetopnav:true
        },
        component: () => import('@/views/members/members.vue')
      },
      {
        path: '/member-details', 
        name: 'memberdetails', 
        meta: {
          requiresAuth: true,
          title:'Members',
          hidetopnav:true
        },
        component: () => import('@/views/members/member-details.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/schedule', 
        name: 'schedule', 
        meta: {
          requiresAuth: true,
          title:'Dashboard',
          hidetopnav:true
        },
        component: () => import('@/views/schedule/schedule.vue')
      },
    ]
  }, 
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/create-schedule', 
        name: 'create schedule', 
        meta: {
          requiresAuth: true,
          title:'Dashboard',
          hidetopnav:true
        },
        component: () => import('@/views/schedule/createSchedule.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/create-task', 
        name: 'create task', 
        meta: {
          requiresAuth: true,
          title:'Dashboard',
          hidetopnav:true
        },
        component: () => import('@/views/task/createTask.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/create-document', 
        name: 'create Document', 
        meta: {
          requiresAuth: true,
          title:'createDocument',
          hidetopnav:true
        },
        component: () => import('@/views/documents/createDocument.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/create-member', 
        name: 'create Member', 
        meta: {
          requiresAuth: true,
          title:'createMember',
          hidetopnav:true
        },
        component: () => import('@/views/members/createMember.vue')
      },
    ]
  },
  {
    path: '/switch-account',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/switch-account', 
        name: 'Switch Account', 
        meta: {
          requiresAuth: true,
          title:'Switch Account',
          hidetopnav:true
        },
        component: () => import('@/components/switchAccount.vue')
      },
    ]
  },
  {
    path: '/give-proxy',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/give-proxy', 
        name: 'giveProxy', 
        meta: {
          requiresAuth: true,
          title:'giveProxy',
          hidetopnav:true
        },
        component: () => import('@/components/giveProxy.vue')
      },
    ]
  },
  {
    path: '/notifications',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/notifications', 
        name: 'notifications', 
        meta: {
          requiresAuth: true,
          title:'notifications',
          hidetopnav:true
        },
        component: () => import('@/components/notifications.vue')
      },
    ]
  },
  
]

const router = new VueRouter({
  routes
})

export default router
